import React from "react";
import styled from "styled-components";
import { Check } from "lucide-react";
import { SelectedIntegrationInfo } from "./IntegrationInfoHeaders";

type TableCellProps = {
  isLastRow: boolean;
};

const TableCell = styled.div<TableCellProps>`
  box-shadow: ${(props) =>
    props.isLastRow
      ? "1px 0px 0px #eaeef3, 0px 1px 0px #C8CFDA"
      : "1px 0px 0px #eaeef3, 0px 1px 0px #eaeef3"};
`;

type FieldSupportedIntegration = {
  [key: string]: any;
};
export const checkIfFieldIsSupported = (
  fieldSupportedIntegrations: FieldSupportedIntegration,
  integrationName: string,
  fieldName: string,
) => {
  const fieldsSupportedForIntegration = fieldSupportedIntegrations[integrationName];
  if (fieldName === "modified_at" || fieldName === "created_at") return true;
  if (fieldSupportedIntegrations) {
    return fieldsSupportedForIntegration.find((supportedResponseField) => {
      return supportedResponseField.name == fieldName;
    });
  }
  return false;
};

type CommonModelFieldIntegrationSupportRowProps = {
  integrationInformation: SelectedIntegrationInfo[];
  fieldSupportedIntegrations?: FieldSupportedIntegration;
  fieldName: string;
  isLastRow: boolean;
};

const CommonModelFieldIntegrationSupportRow = ({
  fieldName,
  integrationInformation,
  fieldSupportedIntegrations,
  isLastRow,
}: CommonModelFieldIntegrationSupportRowProps) => {
  const supportedIntegrationsForField = Object.keys(fieldSupportedIntegrations!);
  return (
    <>
      {integrationInformation.map((integration) => {
        return (
          <TableCell
            className="py-2 px-3 h-9 w-[164px] flex justify-center items-center"
            isLastRow={isLastRow}
          >
            {supportedIntegrationsForField.includes(integration.integrationName) &&
            checkIfFieldIsSupported(
              fieldSupportedIntegrations!,
              integration.integrationName,
              fieldName,
            ) ? (
              <div className="rounded-full h-4 w-4 bg-teal-50 flex items-center justify-center">
                <Check className="text-white" size={12} />
              </div>
            ) : (
              ""
            )}
          </TableCell>
        );
      })}
    </>
  );
};

export default CommonModelFieldIntegrationSupportRow;
