import React, { useEffect } from "react";
import MultiSelectSearchBar from "./MultiSelectSearchBar";

type SuggestedSearchProps = {
  title: string;
  className?: string;
  dropDownOptions: string[];
  handleOptionSelect: any;
  selectedOptions: string[];
  style?: React.CSSProperties;
};

const SuggestedSearch = ({
  className,
  title,
  dropDownOptions,
  handleOptionSelect,
  selectedOptions,
}: SuggestedSearchProps) => {
  const mappedDropdownOptions = dropDownOptions.map((option) => ({ value: option, label: option }));

  return (
    <div className={className}>
      <div className="text-med text-slate-90 font-semibold mb-2">{title}</div>
      <MultiSelectSearchBar
        dropDownOptions={mappedDropdownOptions}
        value={selectedOptions.map((option) => ({ value: option, label: option }))}
        onChange={(selectedOptions, actionMeta) => handleOptionSelect(selectedOptions, actionMeta)}
      />
    </div>
  );
};

export default SuggestedSearch;
