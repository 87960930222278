import React from "react";
import styled from "styled-components";
import { AlertTriangle } from "lucide-react";
import { palette } from "styles/theme";
import Tooltip from "components/generic/Tooltip";
import { StyledIconContainer } from "../navigation/TertiaryLinkEntryContent";
import { StaticImage } from "gatsby-plugin-image";

const ColumnHeader = styled.div`
  box-shadow:
    1px 0px 0px #eaeef3,
    0px 1px 0px #eaeef3;
`;

export type SelectedIntegrationInfo = {
  integrationName: string;
  integrationSlug: string;
  integrationSquareImage: string;
};

type IntegrationsInfoHeadersProps = {
  integrationInformation: SelectedIntegrationInfo[];
};

const IntegrationsInfoHeaders = ({ integrationInformation }: IntegrationsInfoHeadersProps) => (
  <>
    {integrationInformation.map((integration) => {
      const isRippling = integration.integrationName === "Rippling";
      const isGusto = integration.integrationName == "Gusto";

      return (
        <ColumnHeader
          className="w-[164px] h-9 py-2 pl-3 pr-6 flex font-semibold text-gray-90 truncate items-center justify-center cursor-default"
          key={integration.integrationName}
        >
          {isGusto ? (
            <StyledIconContainer className="mr-2">
              <StaticImage src="../../../assets/images/gusto_generic.png" alt="" />
            </StyledIconContainer>
          ) : (
            <img
              className="h-6 w-6 mr-1 align-middle m-w-full"
              src={integration.integrationSquareImage}
            />
          )}

          <div className="truncate" title={integration.integrationName}>
            {integration.integrationName}
          </div>
        </ColumnHeader>
      );
    })}
  </>
);
export default IntegrationsInfoHeaders;
