import { APICategory } from "@merge-api/merge-javascript-shared";

const getDefaultCommonModelforCategory = (categoryName: APICategory) => {
  switch (categoryName) {
    case APICategory.hris:
      return ["Employee"];
    case APICategory.ats:
      return ["Candidate"];
    case APICategory.accounting:
      return ["JournalEntry"];
    case APICategory.ticketing:
      return ["Ticket"];
    case APICategory.crm:
      return ["Account"];
    case APICategory.mktg:
      return ["MarketingEmail"];
    case APICategory.filestorage:
      return ["File"];
    default:
      return [];
  }
};

export { getDefaultCommonModelforCategory };
